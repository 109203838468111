export const seConstants = {
  GO_TO: 'GO_TO',
  GO_TO_NEXT: 'GO_TO_NEXT',
  GO_TO_PREV: 'GO_TO_PREV',
  GET_QUESTIONS: 'GET_QUESTIONS',
  UPDATE_ANSWER_KEY: 'UPDATE_ANSWER_KEY',
  SET_DROPDOWN_KEY: 'SET_DROPDOWN_KEY',
  POST_ANSWERS: 'POST_ANSWERS',
  POST_USER_DATA: 'POST_USER_DATA',
  DISABLE_NEXT: 'DISABLE_NEXT',
  SET_NAV_NEXT_VISIBILITY: 'SET_NAV_NEXT_VISIBILITY',
  VALIDATE_REQUIRED_KEYS: 'VALIDATE_REQUIRED_KEYS',
  TRACKING: 'TRACKING',
  COMPLETE_TRACKING: 'COMPLETE-TRACKING',
  START_TRACKING: 'START-TRACKING',
};
