import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Styled from './Main.style';
import * as _ from 'lodash';
import { Error404 } from '../../Pages';
import { Header, Footer } from '../../Layout';
import RenderContainer from '../../RenderContainer';
import PrivateRoute from '../../PrivateRoute';
import { getVariable } from '../../../helpers';
import { Col, Row } from 'react-styled-flexboxgrid';
import routes from '../../../routes';
import { Cookie } from '@savewatersavemoney/swsm-ui';
import { Landing, ResetPassword, Login, PageUnsubscribe, HindiLanding } from '../../Pages/lazy';
import { AppReducer } from '../../../interfaces';
import LinkHandler from '../../LinkHandler';
import SubSection from './SubSection';
import Qa from '../../Pages/QA';
import store from 'store2';
import AustraliaLanding from '../../Pages/AustraliaLanding';
import UsaLanding from '../../Pages/UsaLanding';

declare global {
  interface Window {
    _paq: any;
  }
}
class Main extends React.Component<
  {
    location?: any;
    appWidth: number;
    app: AppReducer;
    getConfig: Function;
    setComponentWidth: Function;
    user: any;
    userLocation: any;
    getLocation: Function;
    history?: any;
    bambuser: any;
    reporting: any;
    getTotalRegistration: Function;
    getTotalPotentialCarbonSaving: Function;
    getTotalPotentialMonetarySaving: Function;
    getTotalPotentialWaterSaving: Function;
  },
  any
> {
  private componentRef = React.createRef<HTMLDivElement>();
  private swsmInternal = getVariable('mode', 'swsm', false);
  private swsmClient = getVariable('client', 'waterClient');
  componentDidMount() {
    this.initializeConfig();
    this.updateWidth(this.props.appWidth);
    if (this.props.location.pathname === '/' && this.swsmClient === 'www') {
      this.props.getTotalRegistration();
      this.props.getTotalPotentialCarbonSaving();
      this.props.getTotalPotentialMonetarySaving();
      this.props.getTotalPotentialWaterSaving();
    }

    window.addEventListener('resize', this.debounceWidth);
    localStorage.setItem('i18nextLng', 'hi-IN');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceWidth);
  }

  componentDidUpdate(prevProps) {
    this.initializeConfig();
    this.updateWidth(prevProps.appWidth);
  }

  initializeConfig = () => {
    const { done, loading } = this.props.app.config.status;
    if (!done && !loading) {
      this.props.getConfig();
    }
  };

  updateWidth(appWidth) {
    if (this.componentRef.current && this.swsmInternal) {
      if (appWidth !== this.componentRef.current.clientWidth) {
        this.props.setComponentWidth(this.componentRef.current.clientWidth);
      }
    }
  }

  debounceWidth = _.debounce(() => {
    this.updateWidth(this.props.appWidth);
  }, 300);

  buildRoutes = () => {
    return _.map(routes, (route, path) => {
      if (!route.authRequired) {
        return <Route key={path} exact path={path} component={route.template} />;
      }
      return <PrivateRoute key={path} exact path={path} render={route.template} />;
    });
  };

  handleQARedirect = () => {
    const locationData = store('swsmLocation');
    // const tracking = window._paq;
    if (_.get(locationData, 'postcode_store')) {
      // tracking.push(['setCustomDimension', 1]);
      return true;
    }
    return false;
  };

  routes = () => {
    if (this.props.user.data) {
      return <Redirect exact from="/" to={'portal'} />;
    }
    return <Route exact path="/" component={Landing} />;
  };

  render() {
    const { swsmInternal } = this;
    const { location, appWidth, reporting } = this.props;
    const { config } = this.props.app;

    const { loading, done, error } = config.status;
    const homepage = location.pathname === '/';
    const hindiHomepage = location.pathname === '/india';
    const australiaHomepage = location.pathname === '/australia';
    const usaHomepage = location.pathname === '/usa';
    const isDesktop = appWidth > 800;
    const env = getVariable('mode', 'env');
    const waterClient = _.get(this, 'props.user.data.waterClient');
    const australia = getVariable('client', 'waterClient', 'aus');
    const usa = getVariable('client', 'waterClient', 'usa');
    const qa = location.pathname === '/questions';
    const tracking = window._paq;
    const totalRegistration = _.get(reporting, 'getRegistration.data.data.total_registrations');
    const waterSaving = _.get(reporting, 'getWaterSaving.data.data.total_potential_water_saving');
    const carbonSaving = _.get(reporting, 'getCarbonSaving.data.data.total_potential_carbon_saving');
    const moneySaving = _.get(reporting, 'getMoneySaving.data.data.total_potential_monetary_saving');

    if (this.props.user.data && this.handleQARedirect() === true && waterClient && swsmInternal) {
      tracking?.push(['setCustomDimension', 1, waterClient]);
    }
    if (error) {
      return 'Error';
    }
    if (loading && !done) {
      return null;
    }
    if (!config.data) {
      return 'No pages';
    }

    const integratedStyles = !swsmInternal
      ? { display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center' }
      : null;

    // Optimize to build one and put in state
    return (
      <>
        <Header
          env={env}
          isDesktop={isDesktop}
          location={location}
          homepage={homepage || hindiHomepage || australiaHomepage || usaHomepage}
          qa={qa}
        />
        <Styled.Main
          isDesktop={isDesktop}
          bg={location.pathname === '/' || hindiHomepage || australiaHomepage || usaHomepage}
          env={env}
        >
          <RenderContainer
            env={env}
            id="main"
            style={swsmInternal ? null : { margin: 'auto', width: '100%' }}
            homepage={homepage || hindiHomepage || australiaHomepage || usaHomepage}
          >
            <Row
              id="content-row"
              style={swsmInternal ? null : { margin: 'auto', width: '100%' }}
              ref={this.componentRef}
            >
              <Col xs={12} style={{ ...integratedStyles }}>
                <Switch>
                  {this.routes()}
                  {this.buildRoutes()}
                  <Route exact path="/questions" component={Qa} />
                  <Route exact path="/india" component={HindiLanding} />
                  <Route exact path="/australia" component={AustraliaLanding} />
                  <Route exact path="/usa" component={UsaLanding} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/resetpassword" component={ResetPassword} />
                  <Route exact path="/unsubscribe" component={PageUnsubscribe} />
                  <Route component={Error404} />
                </Switch>
              </Col>
            </Row>
          </RenderContainer>
          {homepage && swsmInternal && australia !== 'aus' && usa !== 'usa' ? (
            <SubSection
              registrations={totalRegistration}
              carbon={carbonSaving}
              water={waterSaving}
              money={moneySaving}
              isDesktop={isDesktop}
            />
          ) : null}
        </Styled.Main>
        {swsmInternal && <Footer location={location} />}
        {swsmInternal && (
          <Cookie>
            <h3>Use of cookies</h3>
            <p>
              We only use essential functional cookies to make our website work. No analytics cookies are in use on this
              site. View our cookie policy <LinkHandler path="/cookies">here</LinkHandler>.
            </p>
          </Cookie>
        )}
      </>
    );
  }
}

export default Main;
