import { range, colors } from '../assets/variables';
import * as _ from 'lodash';
import { ChartData } from '../interfaces';
import { convertToTimeZone } from 'date-fns-timezone';
import getTime from 'date-fns/getTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import store from 'store2';
import React from 'react';
import { prettifyIfNumber } from './formatters';

declare global {
  interface Window {
    swsm: any;
  }
}

export const getCurrentLocation = pathName => {
  const activeLink = pathName.split('/');
  const pathFormatted = activeLink[activeLink.length - 1];
  return pathFormatted;
};

export const scrollToTop = () => {
  if (window.scrollTo) {
    window.scrollTo({ top: 0 });
  }
};
export const scrollToTopAnimated = () => {
  if (window.scrollTo) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const scrollIntoView = id => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
};

export const timestampNow = () => {
  const date = Date.now();
  const timeZone = 'Europe/London';
  const zonedDate = convertToTimeZone(date, { timeZone });

  return getTime(zonedDate);
};

export const timestampToHuman = timestamp => {
  return fromUnixTime(timestamp / 1000);
};

export const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const displayTimeLeft = endDate => {
  const minutesLeft = Math.ceil((endDate - timestampNow()) / 60000);

  if (minutesLeft >= 1440) {
    const days = Math.ceil(minutesLeft / 1440);
    return `${pluralize(days, 'day')}`;
  }
  if (minutesLeft >= 60) {
    const hours = Math.ceil(minutesLeft / 60);
    return `${pluralize(hours, 'hour')}`;
  }
  if (minutesLeft === -0) {
    return 'less than a minute';
  }
  if (minutesLeft >= 0) {
    return `${pluralize(minutesLeft, 'minute')}`;
  }
  return false;
};

export const displayTimePassed = endDate => {
  const minutesLeft = Math.ceil((timestampNow() - endDate) / 60000);

  if (minutesLeft >= 1440) {
    const days = Math.ceil(minutesLeft / 1440);
    return `Over ${pluralize(days, 'day')} ago`;
  }
  if (minutesLeft >= 60) {
    const hours = Math.ceil(minutesLeft / 60);
    return `Over ${pluralize(hours, 'hour')} ago`;
  }
  if (minutesLeft === 1) {
    return 'Just now';
  }
  if (minutesLeft >= 0) {
    return `Over ${pluralize(minutesLeft, 'minute')} ago`;
  }
  return '';
};

export function getVariable(scope: string, name: string, fallback: any = '') {
  return _.get(window, `swsm.${scope}.${name}`, fallback);
}

export function getMicroServiceUrl(name: string, version: string = '1.0') {
  const env = getVariable('mode', 'env');
  if (env === 'dev') {
    return `https://api.dev.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  else if (env === 'stage') {
    return `https://api.stage.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  else if (env === 'stage-au') {
    return `https://api.stage.smartwateradvice.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'stage-usa') {
    return `https://api.stage.awe.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'usa') {
    return `https://api.awe.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'aus') {
    return `https://api.smartwateradvice.getwaterfit.com/${name}/api/${version}/`;
  }

  return `https://api.getwaterfit.co.uk/${name}/api/${version}/`;
}

export function getV7MicroServiceUrl(name: string, version: string = '1.0') {
  const env = getVariable('mode', 'env');
  if (env === 'dev') {
    return `https://api.dev.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  else if (env === 'stage') {
    return `https://api.stage.getwaterfit.co.uk/${name}/api/${version}/`;
  }
  else if (env === 'stage-au') {
    return `https://api.stage.smartwateradvice.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'stage-usa') {
    return `https://api.stage.awe.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'usa') {
    return `https://api.awe.getwaterfit.com/${name}/api/${version}/`;
  }
  else if (env === 'aus') {
    return `https://api.smartwateradvice.getwaterfit.com/${name}/api/${version}/`;
  }

  return `https://api.getwaterfit.co.uk/${name}/api/${version}/`;
}

export function getMicroServiceAuthUrl(name: string, version: string = '1.0') {
  const env = getVariable('mode', 'env');

  if (env === 'dev') {
    return `https://api.dev.getwaterfit.co.uk/${name}/auth/${version}/`;
  }
  else if (env === 'stage') {
    return `https://api.stage.getwaterfit.co.uk/${name}/auth/${version}/`;
  }
  else if (env === 'stage-au') {
    return `https://api.stage.smartwateradvice.getwaterfit.com/${name}/auth/${version}/`;
  }
  else if (env === 'aus') {
    return `https://api.smartwateradvice.getwaterfit.com/${name}/auth/${version}/`;
  }
  else if (env === 'stage-usa') {
    return `https://api.stage.awe.getwaterfit.com/${name}/auth/${version}/`;
  }
  else if (env === 'usa') {
    return `https://api.awe.getwaterfit.com/${name}/auth/${version}/`;
  }

  return `https://api.getwaterfit.co.uk/${name}/auth/${version}/`;
}

export function getConfigUrl() {
  const env = getVariable('mode', 'env');

  if (env === 'dev') {
    return 'https://cdn.getwaterfit.co.uk/static/files/config/develop/config.json';
  }
  else if (env === 'stage') {
    return 'https://cdn.getwaterfit.co.uk/static/files/config/stage/config.json';
  }
  else if (env === 'stage-au') {
    return 'https://stage.smartwateradvice.getwaterfit.com/config.json';
  }
  else if (env === 'aus') {
    return 'https://www.smartwateradvice.getwaterfit.com/config.json';
  }
  else if (env === 'stage-usa') {
    return 'https://stage.awe.getwaterfit.com/config.json';
  }
  else if (env === 'usa') {
    return 'https://awe.getwaterfit.com/config.json';
  }

  return 'https://cdn.getwaterfit.co.uk/static/files/config/production/config.json';
}

export function colorRange(index: number, colors: string[]) {
  if (colors) {
    const rangeLengthIndexed = colors.length;
    return colors[index % rangeLengthIndexed];
  }

  const rangeLengthIndexed = range.length;
  return range[index % rangeLengthIndexed];
}

export const chartDataColors = (chartData: ChartData, theme: string) => {
  if (chartData.hasOwnProperty('datasets') && Array.isArray(chartData.dataSets) && chartData.dataSets.length > 0) {
    const { dataSets } = chartData;
    return _.map(dataSets, set => {
      return _.get(set, 'theme', theme); // fallback to component theme
    });
  }
  return [theme];
};

export const themeToColor = input => {
  if (Array.isArray(input)) {
    return _.map(input, theme => colors[theme]);
  }
  else if (typeof input === 'string') {
    return colors[input];
  }

  return colors['primary'];
};

export const postAppMessage = msg => {
  const windows = window as any;
  if (windows.webkit !== undefined) {
    if (windows.webkit.messageHandlers.appInterface !== undefined) {
      windows.webkit.messageHandlers.appInterface.postMessage(msg);
    }
  }
  if (windows.appInterface !== undefined) {
    windows.appInterface.postMessage(JSON.stringify(msg));
  }
};

export const renderCdnUrl = el => {
  const env = getVariable('mode', 'env');
  if (env === 'stage-au' || env === 'aus') {
    return `https://cdn.smartwateradvice.getwaterfit.com/static/files/${el}`;
  }
  else if (env === 'stage-usa' || env === 'usa') {
    return `https://cdn.awe.getwaterfit.com/static/files/${el}`;
  }
  return `https://cdn.getwaterfit.co.uk/static/files/${el}`;
};

//Function to extract UTM tags from URL;
export const getUtmtagsFromURL = () => {
  //gets current url and checks if it includes a utm parameter;
  const addr = window.location.href.toLowerCase();
  const hasUtmTags = addr.includes('utm_');

  //if url contains no utm data return undefined;
  if (!hasUtmTags) {
    return undefined;
  }

  //splits url into an array of utm tags and values
  const urlExtraParams = addr.split('?');
  const utmTags = urlExtraParams[1].split('&');

  //iterates over the utm array and creates a new object containing the utm tags as key and value
  // {utm_source: "buffer"}
  let tags = {};
  utmTags.forEach(tag => {
    if (tag.includes('utm')) {
      const keyValue = tag.split('=');
      tags = { ...tags, [keyValue[0]]: keyValue[1] };
      return tags;
    }
  });

  return tags;
};

export const getControlKeys = singleStep => {
  const { controls } = singleStep;
  if (controls && controls.length > 0) {
    const keys: Array<string> = [];
    controls.forEach(control => {
      if (control.key) {
        keys.push(control.key);
      }
      if (control.type === 'multi-checkbox-dropdown') {
        keys.push(control.options[0].label);
      }
    });
    return keys;
  }
  return [];
};

export const getControlTypes = singleStep => {
  const { controls } = singleStep;
  if (controls && controls.length > 0) {
    const types: Array<string> = [];
    controls.forEach(control => {
      if (control.type) {
        types.push(control.type);
      }
    });
    return types;
  }
  return [];
};

export const getRequiredControls = singleStep => {
  const { controls } = singleStep;
  if (controls && controls.length > 0) {
    const required: Array<string> = [];
    controls.forEach(control => {
      if (control.required) {
        required.push(control.key);
      }
    });
    return required;
  }
  return [];
};

export function toPixels(value: number): string {
  return `${value}px`;
}
export function emSuffix(value: number): string {
  return `${value}em`;
}

export const addGroup = data => {
  return _.map(data, (el, index) => {
    const num = parseInt(index, 1);
    const previousSame = _.get(data, `[${num - 1}].type`) === el.type;
    const nextSame = _.get(data, `[${index + 1}].type`) === el.type;
    return {
      ...el,
      grouped: (previousSame || nextSame) && {
        firstInGroup: nextSame && !previousSame,
        inGroup: previousSame || nextSame,
        lastInGroup: previousSame && !nextSame,
      },
    };
  });
};

export const renderPostcode = () => {
  const url = window.location.href;
  const url_now = new URL(url);
  const param = url_now.searchParams.get('postcode');
  return param === '' ? undefined : param;
};

export const isConditionTrue = (answers, id, operator, value = 'false') => {
  const answerString = (answers[id] || false).toString();
  const answerNumber = parseInt(answers[id], 10);
  const andValueNumber = parseInt(value, 10);
  const andValueString = value.toString();

  switch (operator) {
    case '==':
      return answerString === andValueString;
    case '!=':
      return answerString !== andValueString;
    case '>':
      return answerNumber > andValueNumber;
    case '<':
      return answerNumber < andValueNumber;
    case '>=':
      return answerNumber >= andValueNumber;
    case '<=':
      return answerNumber <= andValueNumber;
    default:
      return false;
  }
};

export const getUtmTags = () => {
  const utmStore = store('utm_tags');
  if (utmStore !== null) {
    return utmStore.utm_tags;
  }
  else if (getUtmtagsFromURL() !== undefined) {
    return getUtmtagsFromURL();
  }
  return null;
};

export const utmCheck = () => {
  const utm_tags = getUtmtagsFromURL() !== undefined ? getUtmtagsFromURL() : null;
  if (utm_tags !== null) {
    store('utm_tags', {
      utm_tags,
    });
  }
  else {
    return null;
  }
};

export const filterChallenges = (challenge, state) => {
  let filterHeaders: Array<string> = [];
  //Todo: make this better
  Object.keys(state).map(filter => (filterHeaders = [...filterHeaders, filter]));

  if (state[filterHeaders[1]]?.filterBy !== '' && state[filterHeaders[0]]?.filterBy === '') {
    return challenge.category === state[filterHeaders[1]]?.filterBy || challenge.category === 'any';
  }
  else if (state[filterHeaders[1]]?.filterBy === '' && state[filterHeaders[0]]?.filterBy !== '') {
    if (state[filterHeaders[0]]?.filterBy === 'energy') {
      return challenge.energySaving > 0 || challenge.type === 'any';
    }
    return challenge.type === state[filterHeaders[0]]?.filterBy || challenge.type === 'any';
  }
  else if (state[filterHeaders[1]]?.filterBy !== '' && state[filterHeaders[0]]?.filterBy !== '') {
    if (state[filterHeaders[0]]?.filterBy === 'energy') {
      return (
        (challenge.energySaving > 0 && challenge.category === state[filterHeaders[1]]?.filterBy) ||
        challenge.category === 'any'
      );
    }
    return (
      (challenge.type === state[filterHeaders[0]]?.filterBy &&
        challenge.category === state[filterHeaders[1]]?.filterBy) ||
      (challenge.category === 'any' && challenge.type === 'any')
    );
  }
  return false;
};

export const handleParamSearch = (filterType, setFilter) => {
  //Needs a url check to then filter based on url params
  const type = filterType === 'Filtereasywinsbytype' ? 'type' : 'category';
  const addr = window.location.href.toLowerCase();
  const paramSearch = addr.includes(type);

  //if url contains no searh params data return undefined;
  if (!paramSearch) {
    return;
  }

  scrollIntoView('easywins');

  //splits url into an array of utm tags and values
  const urlExtraParams = addr.split('?');
  const filterBy = urlExtraParams[1].split('&');

  filterBy.forEach(item => {
    if (item.split(`${type}=`)[1] !== undefined) {
      setFilter(item.split(`${type}=`)[1], filterType);
    }
  });
};

export function useTypewriter(words) {
  const [wordIndex, setWordIndex] = React.useState(0);
  const [text, setText] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);

  const type = React.useCallback(() => {
    // Current word
    const currentWord = words[wordIndex];
    // Determine the function to be performed
    const shouldDelete = isDeleting ? 1 : -1;
    // Create the new text
    setText(current => currentWord.substring(0, current.length - shouldDelete));
    // Determine if this word is complete
    if (!isDeleting && text === currentWord) {
      // Make a pause at the end
      setTimeout(() => setIsDeleting(true), 1000);
    }
    else if (isDeleting && text === '') {
      setIsDeleting(false);
      // Move to the next word
      setWordIndex(current => (current + 1) % words.length);
    }
  }, [isDeleting, text, wordIndex, words]);

  React.useEffect(() => {
    const timer = setTimeout(type, isDeleting ? 30 : 90);
    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
    // Add dependencies to the dependency array
  }, [wordIndex, isDeleting, text, type]);

  return text;
}

export function convertWater(lang, litres) {
  if (lang === 'en-CA') {
    const value = litres * 3.785411784;
    return prettifyIfNumber(value);
  }
  return litres;
}
