import styled, { css } from 'styled-components';
import { colors } from '../../assets/variables';

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 19px;
  left: 15px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 1.3em;
  color: ${colors.primary};
`;
const Description = styled.div`
  line-height: 1.6em;
  font-size: 14px;
  color: ${colors.primary};
`;

const checkedStyles = css`
  background-color: ${colors.baseLight};
  border: 2px solid ${colors.primary};
  fill: ${colors.primary};
`;
const uncheckedStyles = css`
  border: 2px solid ${colors.action};
  background-color: ${colors.baseLight};
  fill: ${colors.baseLight};
`;

const disabledStyles = css`
  border: 2px solid ${colors.disabled};
  background-color: ${colors.baseLight};
  cursor: not-allowed;
`;

const Content = styled.div`
  display: block;
`;

const Label = styled.label`
  background-color: ${props => (props.checked ? colors.action : '#d7f1ff')};
  min-width: 380px;
  border: 2px solid ${props => (props.checked ? colors.action : '#d7f1ff')};
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  flex: 1 1 100%;
  max-height: 65px;
  height: 65px;
  padding: 0 12px;
  @media screen and (max-width: 414px) {
    min-width: 236px;
  }

  ${Content} {
    margin-left: 32px;
    color: ${props => (props.disabled ? colors.disabled : 'inherit')};
    margin-top: -1px;
  }

  ${HiddenRadio} {
    ~ ${() => Icon} {
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      ${props => (props.checked ? checkedStyles : uncheckedStyles)};
      ${props => (props.disabled ? disabledStyles : '')}
    }
  }
  ${Icon} {
    stroke: ${props => (props.disabled ? colors.disabled : colors.baseLight)};
  }

  ${Title} {
    color: ${props => (props.disabled ? colors.darkGray : colors.primary)};
  }
  ${Description} {
    color: ${props => (props.disabled ? colors.darkGray : colors.primary)};
  }
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;

export default {
  Content,
  Icon,
  HiddenRadio,
  Label,
  Title,
  Description,
  FieldError,
};
