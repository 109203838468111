import styled from 'styled-components';
import { colors } from '../../../assets/variables';
const Wraper = styled.div`
  margin: 0px auto 8px auto;
  max-width: 400px;
  min-height: 65px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid ${colors.primary};
  font-weight: 600;
  color: ${colors.primary};
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  svg {
    transition: transform 0.3s ease;
    &.rotated {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
`;

const Item = styled.div`
  padding: 16px;
  font-weight: 400;
  text-align: left;
  background: white;
  &:hover {
    background: ${colors.lightGray};
  }
  &.active {
    background: ${colors.tertiary};
  }
`;
export default { Wraper, Item };
