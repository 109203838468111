import styled, { css } from 'styled-components';
import Shared from '../../assets/SharedStyles.style';
// TODO: is that important?
// html {
//   overflow: -moz-scrollbars-vertical;
//   overflow-y: scroll;
//   background-color: #fff;
// }

const StepWrap = styled.div`
  @media screen and (min-width: 414px) {
    padding: 0 8px;
    ${props => {
    if (props.swsmInternal) {
      return css`
          margin-top: 0px;
        `;
    }
  }}
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-grow: 1;
  }
`;

const TransitionElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const TransitionWrap = styled.div`
  position: relative;
  ${Shared.swsmTransition('height', '50ms')}
  margin: 10px auto;
  @media screen and (min-height: 800px) {
    margin: 50px auto;
  }
  ${Shared.clearfix}
  min-height: 500px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge*/
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
`;

const LoaderWrap = styled.div`
  text-align: center;
  width: 300px;
  margin: 200px auto 0;
`;

export default {
  TransitionWrap,
  LoaderWrap,
  TransitionElement,
  StepWrap,
};
