import React from 'react';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { SelectControlProps } from '../../../interfaces';
import Styled from './SelectControl.style';
import { useTranslation } from 'react-i18next';
// import i18next from 'i18next';
// can NOT be combined with others controls
// need interaction to proceed to next step
// need function set up answer
// need to call function to set visible next nav
// need to be able prepare view just by one answer key
// need to be able to hide next nav if not valid answer on not complete

const SelectControl: React.FC<SelectControlProps> = props => {
  const { size, setNavNextVisibility, options, answerKey, updateAnswerKey, value, defaultValue } = props;
  const [isVisible, setVisible] = React.useState(false);
  const [valueDrop, setValuedrop] = React.useState('');
  console.log(valueDrop);
  const [t] = useTranslation('common');
  React.useEffect(() => {
    setNavNextVisibility(true);
  }, [setNavNextVisibility]);

  const handleChange = value => {
    updateAnswerKey(answerKey, value);
    if (value) {
      updateAnswerKey(answerKey, value);
      setNavNextVisibility(true);
    }
    else {
      setNavNextVisibility(false);
    }
  };
  return (
    <Styled.Wraper
      size={size}
      onClick={() => {
        setVisible(!isVisible);
        updateAnswerKey(answerKey, defaultValue);
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        <div style={{ flex: 1, display: 'flex' }}>
          <p style={{ padding: '16px 8px 16px 16px', margin: '0px', textAlign: 'left' }}>
            {t(props.desc)}
            {valueDrop && <span style={{ fontWeight: 400, fontSize: '14px' }}> - {t(valueDrop)}</span>}
          </p>
        </div>
        <div style={{ padding: '0px 16px', marginBottom: '8px' }}>
          <Icon icon="chevronDown" size={20} className={isVisible ? 'rotated' : ''} />
        </div>
      </div>
      {isVisible &&
        options.map((el, index) => {
          return (
            <Styled.Item
              className={value === el.value ? 'active' : ''}
              onClick={() => {
                handleChange(el.value);
                setValuedrop(el.text);
              }}
              key={index}
            >
              {t(el.text)}
            </Styled.Item>
          );
        })}
    </Styled.Wraper>
  );
};

export default SelectControl;
