import axios from 'axios';
import { seConstants } from '../constants';
import { getMicroServiceUrl, getMicroServiceAuthUrl, getUtmTags, getVariable } from '../helpers';
import store from 'store2';

declare global {
  interface Window {
    _paq: any;
  }
}

export const getQuestions = waterClient => {
  const utmTag = store('utm_tags');

  const url = utmTag
    ? `${getMicroServiceUrl('question')}questions/${waterClient}?utmId=${utmTag.utm_tags.utm_id}`
    : `${getMicroServiceUrl('question')}questions/${waterClient}`;
  return {
    type: seConstants.GET_QUESTIONS,
    payload: axios.get(url),
  };
};

export const postAnswers = (answers, qualified, redirectUrl) => {
  return dispatch => {
    const postAnswersRequest = dispatch({
      type: seConstants.POST_ANSWERS,
      payload: axios.post(`${getMicroServiceUrl('data')}saveAnswers`, {
        answers: {
          ...answers,
        },
      }),
    });
    postAnswersRequest.then(() => {
      const swsmInternal = getVariable('mode', 'swsm', false);
      const australia = getVariable('client', 'waterClient', 'aus');
      if (swsmInternal && australia !== 'usa') {
        window._paq.push(['trackEvent', 'qa', 'registration complete']);
      }
      window.location.reload();
    });
  };
};

export const postUserData = (data, answers, redirectUrl) => {
  const utm_tags = getUtmTags();

  if (getUtmTags() !== null) {
    data = { ...data, utm_tags };
  }
  return dispatch => {
    const userDataResponse = dispatch({
      type: seConstants.POST_USER_DATA,
      payload: axios.post(`${getMicroServiceAuthUrl('user')}register`, data),
    });

    userDataResponse.then(() => {
      dispatch(postAnswers(answers, true, redirectUrl));
    });
  };
};

export const setNavNextVisibility = bool => {
  return {
    type: seConstants.SET_NAV_NEXT_VISIBILITY,
    visible: bool,
  };
};

export const validateRequiredKeys = () => {
  return {
    type: seConstants.VALIDATE_REQUIRED_KEYS,
  };
};

export const goToNext = () => {
  return {
    type: seConstants.GO_TO_NEXT,
  };
};

export const goTo = index => {
  return {
    type: seConstants.GO_TO,
    index,
  };
};

export const goToPrev = () => {
  return {
    type: seConstants.GO_TO_PREV,
  };
};

export const modalShow = (modalType, modalProps) => {
  return {
    type: 'MODAL_SHOW',
    modalType,
    modalProps,
  };
};

export const modalHide = () => {
  return {
    type: 'MODAL_HIDE',
  };
};

export const updateAnswerKey = (key, value) => {
  return {
    type: seConstants.UPDATE_ANSWER_KEY,
    key,
    value,
  };
};
export const setDropdownKey = (key, value) => {
  return {
    type: seConstants.SET_DROPDOWN_KEY,
    key,
    value,
  };
};

export const postProgress = (data, type) => {
  const utm_tags = getUtmTags();
  if (getUtmTags() !== null) {
    data = { ...data, utm_tags };
  }
  const trackingAPI = type.toLowerCase();
  return dispatch =>
    dispatch({
      type,
      payload: axios.post(`${getMicroServiceUrl('question')}${trackingAPI}`, { ...data }),
    });
};
