import { connect } from 'react-redux';
import Question from './Question';
import { actionsSavingEngine } from '../../../actions/qaIndex';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state, ownProps) => {
  return {
    conditionalKeysVisibility: state.savingengineReducer.conditionalKeysVisibility,
    data: state.savingengineReducer.steps[ownProps.currentStep],
    answers: state.savingengineReducer.answers,
    dropdownValue: state.savingengineReducer.dropdownValue,
    currentStep: ownProps.currentStep,
    navNextVisible: state.savingengineReducer.navNextVisible,
    qaProgress: {
      tracking_id: state.savingengineReducer.tracking_id,
      postcode: state.locationReducer.data.postcode,
      water_client_id: state.locationReducer.data.water_client_id,
      question_id: state.savingengineReducer.steps[ownProps.currentStep].id,
    },
  };
};
const mapDispatchToProps = dispatch => {
  return {
    goToPrev: () => dispatch(actionsSavingEngine.goToPrev()),
    goToNext: () => dispatch(actionsSavingEngine.goToNext()),
    modalShow: (modalType, modalProps) => dispatch(actionsSavingEngine.modalShow(modalType, modalProps)),
    updateAnswerKey: (key, value) => dispatch(actionsSavingEngine.updateAnswerKey(key, value)),
    setDropdownKey: (key, value) => dispatch(actionsSavingEngine.setDropdownKey(key, value)),
    setNavNextVisibility: bool => dispatch(actionsSavingEngine.setNavNextVisibility(bool)),
    validateRequiredKeys: () => dispatch(actionsSavingEngine.validateRequiredKeys()),
    postProgress: (data, type) => dispatch(actionsSavingEngine.postProgress(data, type)),
  };
};

const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(Question);

export default QuestionContainer;
