import React from 'react';
import { actionsSavingEngine, actionsModal } from '../../actions/qaIndex';
import { connect } from 'react-redux';
import store from 'store2';
import { colors } from '../../assets/variables';
import { CloseButton, Modal, ModalContent, ModalTitle, ModalResetButton } from './Modal.styles';
import { Icon } from '@savewatersavemoney/swsm-ui';
import i18next from 'i18next';
import history from '../../history';
const ModalReset = props => {
  const resetProgress = () => {
    store.remove('swsm-se');
    store.remove('swsmUser');
    store.remove('swsmLocation');
    props.modalHide();
    history.push('/');
  };

  return (
    <Modal>
      <CloseButton appearance="TRANSPARENT" id="modal_help_close" onClick={props.modalHide}>
        <Icon icon={'times'} size={33} />
      </CloseButton>

      <ModalContent>
        <ModalTitle>{i18next.t('questions.reset_progress_title')}</ModalTitle>
        <Icon icon={'refresh'} size={200} />
        <p>{i18next.t('questions.reset_progress_message')}</p>

        <div className="modal__reset-buttons">
          <ModalResetButton
            id="modal_reset_yes"
            colorPrimary={colors.error}
            colorSecondary={colors.baseLight}
            onClick={() => resetProgress()}
          >
            {i18next.t('questions.reset_progress_confirm')}
          </ModalResetButton>
          <ModalResetButton
            id="modal_reset_no"
            colorPrimary={colors.success}
            colorSecondary={colors.baseLight}
            onClick={props.modalHide}
          >
            {i18next.t('questions.reset_progress_cancel')}
          </ModalResetButton>
        </div>
      </ModalContent>
    </Modal>
  );
};

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuestions: () => dispatch(actionsSavingEngine.getQuestions(ownProps.waterClient)),
    modalHide: () => dispatch(actionsModal.modalHide()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReset);
